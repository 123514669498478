/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}


.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.projectImg {
    float: left;
    width: 400px;
    margin-right: 30px;
}

.projectInfo {
    /*margin-top: -20px;*/
    padding-right: 20px;
}

.blue {
    background-color: #41b1e1;
    box-shadow: 0px 5px 0px 0px #3C93D5;
}

.snazzyButton {
    border-radius: 5px;
    padding: 10px 18px;
    font-size: 18px;
    text-decoration: none;
    margin: 4px;
    color: #fff;
    position: relative;
    display: inline-block;
}

.projectLi {
    position: relative;
    overflow: hidden;
    margin-bottom: 100px;
}


.word-emphasis {
    /*     font-style: italic;*/
    color: #003399;
}

.padded {
    padding: 10px;
}